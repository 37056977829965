@media only screen and (min-width : 992px){
  .case-study {
    opacity: 0; /* Initially hide the case study container */
    transition: opacity 1s ease; /* Add a transition effect for smooth appearance/disappearance */
    padding-bottom: 120px;
    overflow: hidden;
    /* Add other styling properties as needed */
  }
  
  .animate {
    opacity: 1; /* Show the case study container when it has the animate class */
  }
  
  
  .heading {
    font-size: 3vw;
    font-weight: bold;
    text-align: left;
    font-family: 'DM Serif Display';
    margin-left: 2vw;
    transition: 200ms;
  }

  .heading:hover{
    color: #293ECB;
    transition:  200ms;
    cursor: pointer;
  }
  
  .image-container {
    width: 95%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 50px;
    cursor: pointer;
  }
  
  .image {
    width: 100%;
    height: 27vw;
    object-fit: cover;
  }
  
  .details {
    display: flex;
    margin-top: 50px;
    opacity: 0;
    transform: translateX(100%); /* Initially position the details container outside the viewport */
    transition: opacity 1s ease, transform 1s ease; /* Add transitions for opacity and transform */
    overflow: hidden;
  }
  
  .animate {
    opacity: 1;
  }
  
  .animate .details {
    opacity: 1;
    transform: translateX(0); /* Move the details container to its original position */
    overflow: hidden;
  }
  
  .leftside {
    flex: 30%;
    text-align: left;
  }
  
  .rightside {
    flex: 60%;
    text-align: left;
  }
  
  .type, .role, .date {
    font-size: 1.8rem;
    font-family: 'Roboto';
    margin-left: 100px;
  }
  
  .desc {
    font-size: 1.8rem;
    font-family: 'Roboto';
    margin-left: 100px;
    width: 80% ;
  }

  .purpletext{
    color: #293ECB;
  }
  
  /* Additional styling as per your requirements */
  
}  
 
@media only screen and (max-width : 768px){
  .case-study {
    opacity: 1; /* Initially hide the case study container */
    transition: opacity 1s ease; /* Add a transition effect for smooth appearance/disappearance */
    padding-bottom: 120px;
    overflow: hidden;
    /* Add other styling properties as needed */
  }
  .heading {
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    font-family: 'DM Serif Display';
    margin-left: 2vw;
    transition: 200ms;
  }
  .image-container {
    width: 95%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 50px;
  }
  .image {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  .leftside {
    flex: 30%;
    text-align: left;
    padding: 10px 10px 10px 10px;
  }
  
  .rightside {
    flex: 60%;
    text-align: left;
  }
  
  .type, .role, .date {
    font-size: 20px;
    font-family: 'Roboto';
    font-weight: bold;
  }
  
  .desc {
    font-size: 20px;
    font-family: 'Roboto';
  }

  .purpletext{
    color: #293ECB;
  }
}