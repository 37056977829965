@media only screen and (min-width : 992px){
  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  .card-container.show-cards {
    opacity: 1;
    transform: translateY(0);
  }
  
  .card {
    position: relative; /* Add position relative to establish a positioning context */
    display: flex;
    background-color: #EDEDED;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0px 20px 0px 0px;
    width: 900px;
    margin-bottom: 70px;
    max-height: 350px;
  }
  
  .card img {
    width: 190px;
    height: 350px;
    object-fit: cover; /* Crop the image to fit the specified width and height */
    margin-right: 20px; /* Add margin for spacing between image and text */
  }
  
  .card-content {
    text-align: left;
    flex-grow: 1; /* Allow the text to expand and take up remaining space */
  }
  
  .card-content h2 {
    margin-top: 0;
    font-family: 'DM Serif Display';
    color: #293ECB;
    font-size: 3rem;
    font-weight: 5;
    margin-bottom: -10px;
  }
  
  .card-content p {
    margin-bottom: 10px;
    font-size: 1.6rem;
    font-family: 'Roboto';
    color: black;
  }
  
  .btn {
    display: inline-block;
    margin-left: 10%;
    padding: 8px 16px;
    background-color: #293ECB;
    color: #fff;
    font-family: 'DM Serif Display';
    font-size: 1.2vw;
    text-decoration: none;
    border-radius: 4px;
    position: absolute;
    bottom: 20px; /* Position the button at a fixed distance from the bottom */
    left: 50%; /* Center the button horizontally */
    transform: translateX(-50%); /* Adjust horizontal centering */
  }
  
  .btn:hover {
    background-color: #0069d9;
  }
  
}

@media only screen and (max-width : 768px){
  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
    
  }
  .card {
    position: relative; /* Add position relative to establish a positioning context */
    display: flex;
    background-color: #EDEDED;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0px 0px 0px 0px;
    width: 350px;
    margin-bottom: 70px;
   height: 220px;
  }
  .card img {
    width: 100px;
    height: auto;
    object-fit: cover; /* Crop the image to fit the specified width and height */
    margin-right: 20px; /* Add margin for spacing between image and text */
  }
  .card-content {
    text-align: left;
    flex-grow: 1; /* Allow the text to expand and take up remaining space */
    margin-top: 10px;
  }
  .card-content h2 {
    margin-top: 0;
    font-family: 'DM Serif Display';
    color: #293ECB;
    font-size: 23px;
    font-weight: 5;
    margin-bottom: -1px;
    line-height: 28px;
  }
  .card-content p {
    margin-bottom: 10px;
    font-size: 12px;
    font-family: 'Roboto';
    color: black;
    width: 98%;
  }
  .btn {
    display: inline-block;
    margin-left: 10%;
    padding: 5px 15px;
    background-color: #293ECB;
    color: #fff;
    font-family: 'DM Serif Display';
    font-size: 14px;
    text-decoration: none;
    border-radius: 4px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}
}