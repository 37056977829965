    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {
      .menu-container{
        display: none;
      }
      .navbar {
        display: flex;
        align-items: center;
        padding: 10px;
        /* fallback for old browsers */
        /* Chrome 10-25, Safari 5.1-6 */
        /* background: linear-gradient(to right, #cc2b5e, #753a88); */
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      .logo-container {
        cursor: pointer;
        font-family: 'DM Serif Display';
        font-size: 50px;
        color: #5268FF;
        transition: transform 0.3s ease-in-out;
        margin-left: 20px;
      }
      
      .logo-container:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease-in-out;
      }
      

      .options{
        margin-left: auto;
      }
      

      .case {
        position: relative;
        display: inline-block;
        margin: 20px;
        color: black;
        font-size: 25px;
        font-weight: 500;
        cursor: pointer;
        font-family: 'DM Serif Display';
      }

    
      
      .case::before {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 0;
        height: 4px;
        background-color: black;
        transition: width 0.3s ease-in-out;
      }
      
      .case:hover::before {
        width: 100%;
      }

      
      
      .projects {
        position: relative;
        display: inline-block;
        margin: 5px;
        color: black;
        font-size: 25px;
        font-weight: 500;
        cursor: pointer;
        font-family: 'DM Serif Display';
      }
      
      .projects::before {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 0;
        height: 4px;
        background-color: black;
        transition: width 0.3s ease-in-out;
      }
      
      .projects:hover::before {
        width: 100%;
      }

      .contact {
        position: relative;
        display: inline-block;
        /* margin: 20px; */
        margin-left: 20px;
        margin-right: 50px;
        color: black;
        font-size: 25px;
        font-weight: 500;
        cursor: pointer;
        font-family: 'DM Serif Display';
    }

    .contact::before {
      content: '';
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 0;
      height: 4px;
      background-color: black;
      transition: width 0.3s ease-in-out;
    }
    
    .contact:hover::before {
      width: 100%;
    }

      .hamburgericon{
        display: none;
      }
    }

    @media only screen and (max-width: 767px){
      .case{
        display: none;
      }
      .projects{
        display: none;
      }
      .contact{
        display: none;
      }
      .navbar {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        background: white;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, white, white);
        /* Chrome 10-25, Safari 5.1-6 */
        /* background: linear-gradient(to right, #cc2b5e, #753a88); */
        background: linear-gradient(to right, rgb(255, 255, 249), rgb(255, 255, 249));
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        height: 80px;
      }
      .logo-container{
        font-family: 'DM Serif Display';
        font-size: 40px;
        color: #5268FF;
      }
      .logo-container img {
        height: 90px;
        display: flex;
        left: 5px;
        position: absolute;
        font-family: 'DM Serif Display';
        top: 0;
    }
      .hamburgericon{
        display: block;
        display:flex; 
        position:absolute; 
        right:20px;
      }
      .menu-container {
        display: none;
      }
      
      .menu-container.open {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        /* position: absolute; */
        top: 60px;
        right: 0;
        /* width: 100%; */
        z-index: 1;
        padding: 10px;
        background: #cc2b5e;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #cc2b5e, #753a88);
        /* Chrome 10-25, Safari 5.1-6 */
        /* background: linear-gradient(to right, #cc2b5e, #753a88); */
        background: linear-gradient(to right, rgb(255, 255, 249), rgb(255, 255, 249));
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }
      
      .menu-container.open .case,
      .menu-container.open .projects,
      .menu-container.open .contact {
        margin: 10px 0;
        font-size: 1.4rem;
        color: black;
        cursor: pointer;
        text-align: right;
        text-transform: uppercase;
        display: block;
        font-family: 'DM Serif Display';
      }
    
    }

    @media only screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2) {

      .jfslogo {
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 600px;
        margin-right: 50px;
        cursor: 'pointer'
      }

    }
