/* scrollbar.css */

/* Webkit based browsers */
::-webkit-scrollbar {
    width: 15px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #5268FF;
    border-radius: 10px;
  }
  
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #5268FF transparent;
  }
  
  *::-moz-scrollbar-thumb {
    background-color: #5268FF;
    border-radius: 10px;
  }
  